// Импортируйте axiosInstance, если он вам нужен
import axiosInstance from '../../helpers/instance';

const state = {
  cart: null,
  orders: null,
  methods: null,
  order_details: null,
  warehouse_details: null
};

const getters = {};

const actions = {
  async addCart({ commit, dispatch }, body) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post('cart/store_products', body);

      await dispatch('getCart');

      return response.data.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async deleteCart({ commit, dispatch }, body) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post('cart/delete_product', body);

      await dispatch('getCart');

      return response.data.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async reduceCart({ commit, dispatch }, body) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post('cart/reduce_quantity', body);

      await dispatch('getCart');

      return response.data.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async usePromocode({ commit }, body) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post('cart/use_promocode', body);

      return response.data.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async getCart({ commit, store }) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.get('cart/get_cart');

      if (response.data && response.data.data) {
        commit('setCart', response.data.data);
      }

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async getOrders({ commit }, cityId) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.get('orders/create?city_id=' + cityId);

      if (response.data && response.data.data) {
        commit('setOrders', response.data.data);
      }

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async createOrder({ commit }, body) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post('orders/store' , body);

      return response.data.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async getDeliveryMethods({ commit },) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.get('admin/delivery_methods/index');

      if (response.data && response.data.data) {
        commit('setMethods', response.data.data);
      }

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async usePromocode({ commit }, code) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post('cart/use_promocode', code);

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async paySuccess({ commit, dispatch }, body) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post('/orders/payment/success', body);

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async payFail({ commit, dispatch }, body) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post('/orders/payment/fail', body);

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async getOrderDetails({ commit, dispatch }, id) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.get('/cabinet/profile/order/find?order_id=' + id,);

      if (response.data && response.data.data) {
        commit('setOrderDetails', response.data.data);
      }

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async getWarehouseDetails({ commit, dispatch }, id) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.get('/cabinet/profile/virtual_warehouse/find_user_product?product_id=' + id,);

      if (response.data && response.data.data) {
        commit('setWarehouseDetails', response.data.data);
      }

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async createWarehouseOrder({ commit }, body) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post('/cabinet/profile/virtual_warehouse/store_order' , body);

      return response.data.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
};

const mutations = {
  setOrders(state, data) {
    state.orders = data;
  },
  setMethods(state, data) {
    state.methods = data;
  },
  setCart(state, data) {
    state.cart = data;
  },
  setOrderDetails(state, data) {
    state.order_details = data;
  },
  setWarehouseDetails(state, data) {
    state.warehouse_details = data;
  },
  clearCart(state, data) {
    state.cart = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
